import React, { useContext } from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { CardListStyled, CardItemStyled, CardQuesionsStyled } from "./style"
import Text from "../../ui/Text"
import cardBgBlue from "../images/card_bg_blue.png"
import cardBgGray from "../images/card_bg_gray.png"
import cardBgLineWhite from "../images/card_bg_line_white.png"
import cardBgPink from "../images/card_bg_pink.png"
import checkIcon from "../images/icons/check.svg"
import PlansContext from "../context"

interface CardProps {
  image: string
  title: string
  description?: string
  features: String[]
}

const cards: Array<CardProps> = [
  {
    image: cardBgPink,
    title: "Creative Services & Content Migration",
    features: [
      "Creative design services",
      "Handbook content migration",
      "Dedicated account manager",
      "One-click handbook distribution",
    ],
  },
  {
    image: cardBgGray,
    title: "Automatic Versioning",
    features: [
      "Handbook version history",
      "Easily restore old versions",
      "Track content changes",
    ],
  },
  {
    image: cardBgBlue,
    title: "Digital Signatures",
    features: [
      "Signature notifications",
      "Signature reporting & exports",
      "Custom acknowledgement message",
      "Signature version tracking",
    ],
  },
  {
    image: cardBgLineWhite,
    title: "Real-time Updates",
    features: [
      "Unlimited content edits",
      "Push edits to published handbooks",
      "Send custom notifications",
    ],
  },
  {
    image: cardBgPink,
    title: "Automated signature reminders",
    features: [
      "Custom reminder emails",
      "Customize reminder frequency",
      "Branded emails",
    ],
  },
  {
    image: cardBgPink,
    title: "Import Employees",
    description: ".csv, REST, SAML",
    features: [
      "HRIS integrations (e.g. BambooHR, Workday, ADP, Sharepoint)",
      "Bulk CSV imports",
      "Automated importing via REST API",
      "SAML SSO provisioning (e.g. Onelogin, Okta, Google, Auth0)",
      "Custom integration solutions",
    ],
  },
  {
    image: cardBgGray,
    title: "Collaborators",
    description: "with an approval system",
    features: [
      "Unlimited collaborators",
      "Track & approve content changes",
      "Role-based access control",
    ],
  },
  {
    image: cardBgGray,
    title: "Single Sign-on Authentication",
    features: [
      "SAML SSO integrations (e.g. Onelogin, Okta, Google, Auth0)",
      "Track content changes",
    ],
  },
  {
    image: cardBgBlue,
    title: "Security & Privacy Compliance",
    features: [
      "GDPR & CCPA ready",
      "ADA, WCAG, Section 508 compliant",
      "Enterprise level SLA & security",
      "HIPAA compliant deployment options",
      "Enterprise deployment options (on-perm, single tenant)",
    ],
  },
  {
    image: cardBgLineWhite,
    title: "Product training",
    features: ["Live online product training", "Staff training webinars"],
  },
]

const CardItem = ({ data }: { data: CardProps }) => {
  return (
    <CardItemStyled style={{ backgroundImage: "url(" + data.image + ")" }}>
      <div className="card-title">{data.title}</div>
      {data.description && (
        <div className="card-description">({data.description})</div>
      )}
      <div className="card-features">
        {data.features.map((feature, index) => (
          <div key={index} className="flex mt-5 items-start">
            <img src={checkIcon} alt="check" className="icon-check" />
            <div className="features-item">{feature}</div>
          </div>
        ))}
      </div>
    </CardItemStyled>
  )
}

const CardQuesions = () => {
  const { openContactSalesModal } = useContext(PlansContext)

  return (
    <CardQuesionsStyled>
      <div className="title-quesions">Have quesions?</div>
      <div className="button" onClick={openContactSalesModal}>
        Contact Sales
      </div>
    </CardQuesionsStyled>
  )
}

const CardList = () => {
  const firstColCards = cards.filter((_card, idx) => {
    return idx % 2 === 0
  })
  const secondColCards = cards.filter((_card, idx) => {
    return idx % 2 !== 0
  })

  return (
    <React.Fragment>
      <Text.title
        css={css`
          ${tw`pb-14`}
        `}
      >
        It’s all here
      </Text.title>
      <CardListStyled className="desktop">
        <div className="first-col">
          {firstColCards.map((card, index) => (
            <CardItem key={index} data={card} />
          ))}
        </div>
        <div className="second-col">
          {secondColCards.map((card, index) => (
            <CardItem key={index} data={card} />
          ))}
          <CardQuesions />
        </div>
      </CardListStyled>
      <CardListStyled className="mobile">
        {cards.map((card, index) => (
          <CardItem key={index} data={card} />
        ))}
        <CardQuesions />
      </CardListStyled>
    </React.Fragment>
  )
}

export default CardList
