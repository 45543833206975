import React, { ReactElement } from "react"
import config from "src/config"

export enum BillingCycle {
  year = "year",
  month = "month",
}

interface AvailableFeature {
  startUp: string | boolean
  smallBiz: string | boolean
  enterprise: string | boolean
}

export interface FeaturesPlanDataProps {
  title: string
  desc?: ReactElement
  month: AvailableFeature
  year: AvailableFeature
  customContainerClassName?: string
}

export const featuresPlanData: Array<FeaturesPlanDataProps> = [
  {
    title: "Digital Handbooks",
    desc: (
      <>
        We host your digital handbooks. It is available to employees as a
        mobile-responsive website, complete with search functionalities built
        in. See an example
        <a href="https://app.airmason.com/books/patagonia" target="_blank">
          {" here"}
        </a>
      </>
    ),
    month: {
      startUp: "Unlimited",
      smallBiz: "Unlimited",
      enterprise: "Unlimited",
    },
    year: {
      startUp: "Unlimited",
      smallBiz: "Unlimited",
      enterprise: "Unlimited",
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Digital Signatures",
    desc: (
      <>
        Allow employees to electronically sign their handbooks. You can get a
        notification any time someone signs a new version of the handbook. You
        can, of course, set a customized message for employees to see before
        they sign, and remind them to sign their handbooks with automated
        reminders that you can control. All signatures and versions are tracked
        and available for download.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Automatic Versioning",
    desc: (
      <>
        Easily go back to a previous versions anytime you want. Easily track
        what version someone has signed.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Automated Signature Reminders",
    desc: (
      <>
        Employees aren't exactly running to sign your latest version? Set a
        custom reminder message that gets sent either daily or weekly to only
        those employees who have not signed your latest version.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Real-time Updates",
    desc: (
      <>
        Time to stop using paper, PDFs, and boring hidden wiki pages. In fact,
        whenever your handbook is updated in real-time, we can even email or
        send a push notification to each employee, with a custom message.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Collaborators (with approval system)",
    desc: (
      <>
        Collaborate on your handbook with your team with a built-in approval
        process to make sure, each change can be approved. Made a mistake? Not a
        problem, revert to an earlier version.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Import Employees (.csv, REST, SAML)",
    desc: (
      <>
        You can add employees one by one, or you can use one of our
        easy-integrations that lets you pull your employees from your HRIS
        software as needed. We support CSV import, REST, and SAML with most HRIS
        or authentication systems including OneLogin, Okta, Sharepoint, Google,
        ADP, BambooHR, Workday, Auth0, and more. Need custom integration? If you
        are in the enterprise category, we also support custom integrations as
        needed.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Security & Privacy Compliance ",
    desc: (
      <>
        AirMason is ready for GDPR and allows SSO with 2-factor authentication
        if required. Small Biz and Enterprise clients can also request a
        dedicated VPC configuration which is ready for HIPAA or ISO Compliance
        as needed.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Automatic Distribution",
    desc: (
      <>
        Organize your employees within the AirMason dashboard to ensure everyone
        is receiving the correct handbook. With a click of a button, send your
        updates in real time to as many employees as needed.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "SSO (Single Sign-on) Authentication",
    desc: (
      <>
        Let employees use the existing authentication methods to view the
        handbooks. We support OneLogin, Okta, Sharepoint, Google, ADP, BambooHR,
        Workday, Auth0, and more. Need custom integration, just ask.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Product Training",
    desc: (
      <>
        Once your network of handbooks are complete and ready to distribute,
        schedule a demo with our product specialists to review all
        functionalities of your new handbook platform.
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Dedicated Account Manager",
    month: {
      startUp: false,
      smallBiz: false,
      enterprise: true,
    },
    year: {
      startUp: false,
      smallBiz: false,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Content Migration",
    desc: (
      <>
        Simply send us your document (Word, PDF, PPT etc) of content and our
        team will take care of uploading this into AirMason for you, before
        migrating it into your account ready to get designing!
      </>
    ),
    month: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    year: {
      startUp: true,
      smallBiz: true,
      enterprise: true,
    },
    customContainerClassName: "w-wider25",
  },

  {
    title: "Creative Services",
    desc: (
      <>
        Our team of talented designers can turn your content into a beautifully
        engaging, interactive digital document - all within your brand
        guidelines, of course! We'll work with you to determine the look and
        feel before bringing documents to life right here on AirMason (see an
        example
        <a
          href="https://editor.airmason.com/books/template-32772"
          target="_blank"
        >
          {" here"}
        </a>
        ).
      </>
    ),
    month: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    year: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    customContainerClassName: "w-wider25",
  },

  {
    title: "Copywriting",
    month: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    year: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    customContainerClassName: "w-wider25",
  },
  {
    title: "Automated Policy Updates",
    desc: (
      <>
        Keep track of all State and Federal policy changes and get updated
        directly within your AirMason account. Each suggested change is reviewed
        carefully by an HR professional and attorney and is rewritten in a more
        engaging and digestible tone for a better reader experience, while
        keeping its legal integrity.
        <a href="https://airmason.com/automated-policy-updates" target="_blank">
          {" Click here "}
        </a>
        for more information.
      </>
    ),
    month: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    year: {
      startUp: "Add-on",
      smallBiz: "Add-on",
      enterprise: "Add-on",
    },
    customContainerClassName: "w-wider25",
  },
]

export interface PlansProps {
  type: string
  name: string
  minEmployeeRange?: number
  maxEmployeeRange?: number
  costMonth?: number
  costYearTotal?: number
  yearPlan?: string
  monthPlan?: string
  oldPrice?: number
  employeeRange?: string
  calendlyLink?: string
}

export const plans: Array<PlansProps> = [
  {
    type: "start_up",
    name: "Startup",
    minEmployeeRange: 1,
    maxEmployeeRange: 99,
    costMonth: 149,
    costYearTotal: 999,
    yearPlan: config.PRICING_PLANS.START_UP.YEARLY,
    monthPlan: config.PRICING_PLANS.START_UP.MONTHLY,
    oldPrice: 99,
    calendlyLink: config.CALENDLY.STARTUP,
  },
  {
    type: "small_biz",
    name: "Small Business",
    minEmployeeRange: 100,
    maxEmployeeRange: 199,
    costMonth: 199,
    costYearTotal: 1499,
    yearPlan: config.PRICING_PLANS.SMALL_BUSINESS.YEARLY,
    monthPlan: config.PRICING_PLANS.SMALL_BUSINESS.MONTHLY,
    oldPrice: 149,
    calendlyLink: config.CALENDLY.SMALL_BUSINESS,
  },
  {
    type: "enterprise",
    name: "Enterprise",
    employeeRange: "200+",
    calendlyLink: "/book-demo",
  },
]
