/** @jsxRuntime classic /
/* @jsx jsx */
import { useContext } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

/** @jsx jsx */
// @ts-ignore
import { jsx } from "@emotion/core"

import { openNewTab } from "src/utils/openNewTab"
import { BillingCycle } from "src/componentsV2/shared/plansData"

import { ButtonOutLineBlue, ButtonContact } from "../../ui/Button/styleButton"
import Text from "../../ui/Text"
import bgPlan from "../images/bg_plan.png"
import bgPlanFirst from "../images/bg_plan_first.png"
import PlansContext from "../context"

const Name = styled.div`
  line-height: 37px;
  font-family: Calluna-Regular;
  ${tw`text-black font-medium text-3xl mb-4`}
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
`

const ContactSaleStyled = styled.div`
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: rgb(15, 23, 34);
  cursor: pointer;
  border-color: rgb(16, 122, 241);
  transition: all 0.3s ease-out;
  padding: 10px 19px;
  border-width: 1.5px;
  font-weight: bold;
  font-size: 0.875rem;
  outline: 0px;
  &:hover {
    box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.15);
    background-color: rgb(16, 122, 241);
    color: #fff;
  }
`

const PanelBottom = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 35px;
`

const ButtonContactStyled = styled(ButtonContact)`
  padding: 10px 28px;
`

const ButtonOutLineBlueStyled = styled(ButtonOutLineBlue)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  padding: 10px 19px;
`

const EmployeeRange = styled(({ className, children }: any) => (
  <section className={className}>
    {children}
    {" employees"}
  </section>
))`
  margin-bottom: 20px;
`

const Cost = styled(({ className, billingCycle, plan }: any) => {
  const costYearTotalFormat = Intl.NumberFormat().format(plan.costYearTotal)
  return (
    <section className={className}>
      <span className="text-22/16 mr-1 text-orange-1 absolute left-0 top-0 pt-2">
        $
      </span>
      <span className="text-50/16 text-orange-1 font-medium ml-3.5">
        {costYearTotalFormat}
        <span className="text-10 text-orange-1 absolute left-0 top-0 pl-1 font-medium">
          *
        </span>
      </span>
      <Text.note className="text-sm font-medium">{`/year`}</Text.note>
      {plan && billingCycle === BillingCycle.year && (
        <div className="mt-2">
          <div className="mt-2 leading-normal">
            <Text.note className="font-italic text-base">
              or ${plan.costMonth} /mo if billed monthly
            </Text.note>
          </div>
        </div>
      )}
    </section>
  )
})`
  margin-top: auto;
  color: #a7aaae;
  position: relative;
  text-align: center;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    text-align: center;
  }
`

const CostContact = styled(({ className }: any) => (
  <section className={className}>
    <div className="label">
      <Text.note>
        Enterprise-grade, custom-tailored
        <br /> for global organizations
      </Text.note>
    </div>
    <div className="line" />
  </section>
))`
  margin-bottom: 10px;
  color: #a7aaae;
  margin-bottom: 50px;
  .label {
    text-align: center;
    font-size: 14px;
    padding: 10px;
    margin: 25px 0px;
    color: #60a042;
    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .line {
    width: 118px;
    border-top: 1px solid #989bae;
    margin: 20px auto;
    opacity: 0.23;
  }
`

const PlanDetails = styled.div``

const PlanPanelsItem = styled(({ className, billingCycle, plan, onChange }) => {
  const Enterprise = "Enterprise"

  const { openContactSalesModal } = useContext(PlansContext)

  return (
    <div className={`${className} ${plan.type}`}>
      <PlanDetails>
        <Name>{plan.name}</Name>
        <EmployeeRange>
          {plan.employeeRange ? (
            <b>{plan.employeeRange}</b>
          ) : (
            <span>
              <b>{plan.minEmployeeRange}</b> to <b>{plan.maxEmployeeRange}</b>
            </span>
          )}
        </EmployeeRange>

        {plan.name !== Enterprise ? (
          <Cost plan={plan} billingCycle={billingCycle} />
        ) : (
          <CostContact />
        )}

        <PanelBottom>
          {plan.name === Enterprise ? (
            <ButtonContactStyled onClick={() => openContactSalesModal()}>
              Contact Us
            </ButtonContactStyled>
          ) : (
            <div className="flex justify-center">
              <ButtonOutLineBlueStyled
                type="submit"
                onClick={() => onChange && onChange(plan.name)}
              >
                Start Trial
              </ButtonOutLineBlueStyled>
              <ContactSaleStyled onClick={() => openContactSalesModal()}>
                Contact us
              </ContactSaleStyled>
            </div>
          )}
          <p
            className={`demo-book text-sm font-black text-blue-1 h-4 cursor-pointer ${
              plan.name === Enterprise ? "mt-6" : "mt-9"
            }`}
            onClick={() => openNewTab(plan.calendlyLink)}
          >
            Book a demo
          </p>
        </PanelBottom>
      </PlanDetails>
    </div>
  )
})`
  flex: 1;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  padding: 60px 20px 35px;
  max-width: 290px;
  width: 100%;
  max-height: 390px;
  height: 100%;
  color: #0f1722;
  font-size: 16px;
  z-index: 1;
  box-shadow: 0 2px 70px 0 rgba(4,2,33,0.13);

  @media (max-width: 767px) {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }

  &.start_up {
    border-radius: 12px 0 0 12px;
    border-right: 1px solid rgba(152, 155, 174, 0.23);
    background-image: url("${bgPlanFirst}");
    background-repeat: no-repeat;
    background-position-y: bottom;
    @media (max-width: 767px) {
      border-radius: 12px;
      box-shadow: 0 2px 70px 0 rgba(4,2,33,0.13);
      border-right: none;
      margin: 0 auto;
    }
  }

  &.small_biz {
    margin-left: 0;
    border-radius: 0 12px 12px 0;
    box-shadow: 75px 2px 70px 0 rgba(4,2,33,0.13);

    @media (max-width: 767px) {
      border-radius: 12px;
      box-shadow: 0 2px 70px 0 rgba(4,2,33,0.13);
      margin: 0 auto;
    }
  }
  
  &.enterprise {
    margin-left: 25px;
    background-image: url("${bgPlan}");
    background-repeat: no-repeat;
    background-position-x: right;
    background-size:45%;
    @media (max-width: 767px) {
      margin-left: 0px;
      margin: 0 auto;
    }
  }

  &.planPanelSlideItem {
    border-radius: 15px;
    display: block;
    margin: unset;
    margin: auto;
    max-width: 100%;
    box-shadow: 1px 1px 4px 0px rgba(4, 2, 33, 0.13),
      -1px -1px 4px 0px rgba(4, 2, 33, 0.13);
  }
`

export default PlanPanelsItem
