import styled, { StyledComponent } from "@emotion/styled"
import tw from "tailwind.macro"

const Text: StyledComponent<any, any, any> & any = styled.p`
  ${tw`font-normal antialiased font-body text-black-1`}
`

Text.title = styled(Text.withComponent("h2"))`
  font-family: Calluna-Regular;
  ${tw`text-black text-9/2xl text-center`}
  ${({ css }) => css}
  @media (max-width: 767px) {
    font-size: 2rem;
  }
`

Text.headline = styled(Text.withComponent("p"))`
  ${tw`text-gray-1 text-xl`}
  ${({ css }) => css}
  @media (max-width: 767px) {
    font-size: 17px;
  }
`

Text.subtitle = styled(Text.withComponent("h2"))`
  ${tw`font-display`}
  ${({ css }) => css}
`

Text.subUpper = styled(Text.withComponent("h3"))`
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 0.9rem!important;
  font-weight: 600;
  opacity: 0.7;
  text-align: center;
  ${tw`text-gray-1 text-xl`}
  ${({ css }) => css}
`

Text.body = styled(Text.withComponent("p"))`
  ${tw`text-base`}
  ${({ css }) => css}
`

Text.note = styled(Text.withComponent("span"))`
  ${tw`text-gray-1 text-sm`}
  ${({ css }) => css}
  @media (max-width: 767px) {
    ${tw`text-center`}
  }
`

Text.subnote = styled(Text.withComponent("span"))`
  ${tw`text-sm`}
  ${({ css }) => css}
`

export default Text
