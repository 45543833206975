/** @jsxRuntime classic /
/* @jsx jsx */
import React from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
/** @jsx jsx */
// @ts-ignore
import { jsx } from "@emotion/core"

import preIcon from "src/componentsV2/images/icons/pre_icon.svg"
import nextIcon from "src/componentsV2/images/icons/next_icon.svg"
import { plans, BillingCycle } from "src/componentsV2/shared/plansData"
import constant from "src/utils/constant"

import "./index.css"
import PlanPanelsItem from "./PlanPanelsItem"
import _Form, { useFormState } from "../../../components/Form"

const Form = _Form as any

const TextBottom = styled.div`
  ${tw`text-gray-1 text-base text-center mt-8`}
`

const FlexNav = styled.div`
  ${tw`text-orange-1 bg-orange-3 text-base text-center mx-auto items-center flex mt-9 font-bold justify-center`}
  max-width: 351px;
  padding: 7px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
  .star {
    ${tw`text-9/2xl mr-3 font-medium`}
    line-height: 43px;
    padding-top: 1.125rem;
    line-height: 0;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`

const TextTitle = styled.h1`
  ${tw`text-black text-9/2xl text-center font-sans font-normal leading-58/16`}
  @media (max-width: 767px) {
    ${tw`text-8`}
  }
`

const PlanPanels = ({ onChange }: any) => {
  const plansFormState = useFormState("plans")

  const billingCycle =
    plansFormState &&
    plansFormState.values &&
    plansFormState.values.billingCycle
      ? BillingCycle.month
      : BillingCycle.year

  var params = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    loop: true,
    navigation: {
      nextEl: ".button-next",
      prevEl: ".button-prev",
    },

    slidesPerView: 1,
    spaceBetween: 0,

    renderPrevButton: () => {
      return (
        <button className="button-prev">
          <img src={preIcon} />
        </button>
      )
    },

    renderNextButton: () => {
      return (
        <button className="button-next">
          <img src={nextIcon} />
        </button>
      )
    },
  }

  return (
    <React.Fragment>
      <div className="planPanelDesktop">
        {plans.map((plan, index) => {
          return (
            <PlanPanelsItem
              key={index}
              className="planPanelItem"
              type={plan.type}
              billingCycle={billingCycle}
              plan={plan}
              onChange={onChange}
            />
          )
        })}
      </div>

      {/* Mobile */}
      <div className="planPanelMobile">
        <Swiper {...params}>
          {plans.map((plan, index) => (
            <div key={index}>
              <PlanPanelsItem
                className="planPanelSlideItem"
                billingCycle={billingCycle}
                type={plan.type}
                plan={plan}
                onChange={onChange}
              />
            </div>
          ))}
        </Swiper>
      </div>
      {/* End mobile */}
      <TextBottom>
        *Your card won't be charged until after your free {constant.trialDays}
        -day trial ends.
      </TextBottom>
    </React.Fragment>
  )
}

const PlanControls = () => {
  return (
    <FlexNav>
      <span className="star">*</span>
      <span>Save up to 44% simply by paying annually!</span>
    </FlexNav>
  )
}

const index = ({ transition }: any) => {
  return (
    <div className="text-center mb-16">
      <TextTitle>
        Pricing is based on how many
        <br /> employees you have.
      </TextTitle>
      <p className="mt-5 text-base text-gray-1">
        Start your free {constant.trialDays} day free trial now
      </p>

      <Form
        name="plans"
        initialValues={{}}
        onSubmit={() => {
          transition()
        }}
      >
        <PlanControls />
        <Form.Field name="selectedPlan" component={PlanPanels} />
      </Form>
    </div>
  )
}

export default index
