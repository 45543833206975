import styled from "styled-components"

export const Button = styled.button`
  font-weight: bold;
  font-size: 0.875rem;
  border-radius: 25px;
  padding: 12px 23px;
  outline: 0px;
  border-width: 1.5px;
  border-style: solid;
  border-image: initial;

  :focus {
    outline: 0;
  }
`

export const ButtonBlue = styled(Button)`
  color: rgb(255, 255, 255);
  cursor: pointer;
  background: rgb(16, 122, 241);
  border-color: rgb(16, 122, 241);
  box-shadow: none;
  transition: all 0.3s ease-out;

  &:hover {
    background: #056bde;
    transform: translateY(-1px);
    box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.25);
  }
`

export const ButtonOutLineBlue = styled(Button)`
  color: rgb(15, 23, 34);
  cursor: pointer;
  background: transparent;
  border-color: rgb(16, 122, 241);
  transition: all 0.3s ease-out;

  &:hover {
    transform: translateY(0px);
    box-shadow: 0 3px 9px -2px rgba(0, 0, 0, 0.15);
    border-color: #0060cc;
    color: #0060cc;
  }
`

export const ButtonWhite = styled(Button)`
  color: #107af1;
  cursor: pointer;
  background: white;
`

export const ButtonContact = styled.div`
  border-radius: 20px;
  background-color: #107af1;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 12px 23px;
  display: inline-block;
  cursor: pointer;
  border: 2px solid rgb(16, 122, 241);
`
