import styled from "styled-components"

export const SupportListStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 80px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    margin-bottom: 15px;
  }
  @media (max-width: 414px) {
    flex-direction: column;
  }
`

interface LayoutProps {
  isRestaurantPage?: boolean
}

export const SupportItemStyled = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 16%;
  margin: 0 auto 35px;
  height: ${props => (props.isRestaurantPage ? "73px;" : "50px;")};
  padding: 0 15px;

  @media (max-width: 767px) {
    width: 50%;
  }
`
