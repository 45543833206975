import React from "react"
import TooltipTrigger from "react-popper-tooltip"
import "react-popper-tooltip/dist/styles.css"

import { TooltipContainer } from "./style"

export const TooltipContent = ({ children }: any) => {
  return <React.Fragment>{children}</React.Fragment>
}

TooltipContent.displayName = "TooltipContent"

export const TooltipPlacement = ({ children }: any) => {
  return <React.Fragment>{children}</React.Fragment>
}

TooltipPlacement.displayName = "TooltipPlacement"

const Tooltip = ({ children, tooltip, hideArrow, ...props }: any) => (
  <TooltipTrigger
    {...props}
    tooltip={({
      arrowRef,
      tooltipRef,
      getArrowProps,
      getTooltipProps,
      placement,
    }) => (
      <TooltipContainer
        {...getTooltipProps({
          ref: tooltipRef,
          className: `tooltip-container ${
            props.customContainerClassName ? props.customContainerClassName : ""
          }`,
        })}
      >
        {!hideArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: "tooltip-arrow",
              "data-placement": placement,
            })}
          />
        )}
        {React.Children.toArray(children).filter(
          (child: any) => child.type.displayName === "TooltipContent"
        )}
      </TooltipContainer>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef,
          className: "trigger cursor-pointer",
        })}
      >
        {React.Children.toArray(children).filter(
          (child: any) => child.type.displayName === "TooltipPlacement"
        )}
      </span>
    )}
  </TooltipTrigger>
)

export default Tooltip
