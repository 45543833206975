import styled from "styled-components"

export const TooltipContainer = styled.div`
  &.tooltip-container {
    max-width: 245px;
    padding: 30px;
    border-radius: 10px;
    background: #0f1722;
    box-shadow: 0px 2px 4px rgba(154, 154, 154, 0.5);
    color: #b0b4d2;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    border: none;
    .tooltip-arrow {
      &[data-placement*="top"] {
        height: 0;
        margin-bottom: 0;
      }
      &::after {
        border-color: #0f1722 transparent transparent transparent;
      }
      &::before {
        border-color: transparent transparent #0f1722 transparent;
        top: 0;
      }
    }
    .tooltip-title {
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 10px;
    }
    a {
      display: contents;
      color: #ed9052;
    }
  }
  &.tooltip-container.w-wider25 {
    max-width: 306px;
  }
`
