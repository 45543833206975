//parners
import timHortons from "src/componentsV2/images/companyLogos/tim_hortons.png"
import sprout from "src/componentsV2/images/companyLogos/sprout.png"
import quibi from "src/componentsV2/images/companyLogos/quibi.png"
import slack from "src/componentsV2/images/companyLogos/slack.png"
import box from "src/componentsV2/images/companyLogos/box.png"
import extrade from "src/componentsV2/images/companyLogos/extrade.png"
import netHealth from "src/componentsV2/images/companyLogos/net.png"
import elastic from "src/componentsV2/images/companyLogos/elastic.png"
import remy from "src/componentsV2/images/companyLogos/remy.png"
import atlassian from "src/componentsV2/images/companyLogos/atlassian.png"
import zendesk from "src/componentsV2/images/companyLogos/zendesk.png"
import oracle from "src/componentsV2/images/companyLogos/oracle.png"

//Digital Images Integrations
import patagonia from "src/componentsV2/images/integrationsLangding/patagonia.png"
import uber from "src/componentsV2/NewHome/DigitalHandbook/images/uber.png"
import netflix from "src/componentsV2/NewHome/DigitalHandbook/images/netflix.png"

//Digital Images Restaurants
import empHandbook from "src/componentsV2/images/restaurant/emp_handbook.png"
import employeeHandbook from "src/componentsV2/images/restaurant/employee_handbook.png"
import grainRestaurant from "src/componentsV2/images/restaurant/grain_restaurant.png"

//Digital Images Healthcare
import healthcareEmpHandbook from "src/componentsV2/images/healthCare/emp_handbook.png"
import healthcareEmployeeHandbook from "src/componentsV2/images/healthCare/employee_handbook.png"

// Digital Images Agencies
import agenciesMarketingEmployeeHandbook from "src/componentsV2/images/agencies/agenciesMarketingEmployeeHandbook.png"
import agenciesEmployeeHandbook from "src/componentsV2/images/agencies/agenciesEmployeeHandbook.png"

//Partner data restaurants
import ricardas from "src/componentsV2/images/restaurant/ricardas.png"
import gupcakes from "src/componentsV2/images/restaurant/gupcakes.png"
import barter from "src/componentsV2/images/restaurant/barter.png"
import food from "src/componentsV2/images/restaurant/food.png"
import pacific from "src/componentsV2/images/restaurant/pacific.png"
import coldenrod from "src/componentsV2/images/restaurant/coldenrod.png"
import relish_works from "src/componentsV2/images/restaurant/relish_works.png"
import peaks_prints from "src/componentsV2/images/restaurant/peaks_prints.png"
import petersen from "src/componentsV2/images/restaurant/petersen.png"
import grain from "src/componentsV2/images/restaurant/grain.png"

//Partner data healthcare
import checkerspot from "src/componentsV2/images/healthCare/checkerspot.png"
import coast_to_coast from "src/componentsV2/images/healthCare/coast_to_coast.png"
import dealnews from "src/componentsV2/images/healthCare/dealnews.png"
import finagraph from "src/componentsV2/images/healthCare/finagraph.png"
import fusebill from "src/componentsV2/images/healthCare/fusebill.png"
import naritiv from "src/componentsV2/images/healthCare/naritiv.png"
import nom from "src/componentsV2/images/healthCare/nom.png"
import rowden from "src/componentsV2/images/healthCare/rowden.png"
import vimond from "src/componentsV2/images/healthCare/vimond.png"

//Partner data agencies
import sevenlayers from "src/componentsV2/images/agencies/7_layers.png"
import assured from "src/componentsV2/images/agencies/assured.png"
import bna from "src/componentsV2/images/agencies/bna.png"
import dg from "src/componentsV2/images/agencies/dg.png"
import hypothesis from "src/componentsV2/images/agencies/hypothesis.png"
import indicate_logo_PR from "src/componentsV2/images/agencies/indicate_logo_PR.png"
import pepperland_logo_long from "src/componentsV2/images/agencies/pepperland_logo_long.png"
import realny from "src/componentsV2/images/agencies/realny.png"

export const commonPartnersData = [
  { brand: "timHortons", src: timHortons },
  { brand: "sprout", src: sprout },
  { brand: "quibi", src: quibi },
  { brand: "slack", src: slack },
  { brand: "box", src: box },
  { brand: "extrade", src: extrade },
  { brand: "nerHealth", src: netHealth },
  { brand: "elastic", src: elastic },
  { brand: "remy", src: remy },
  { brand: "atlassian", src: atlassian },
  { brand: "zendesk", src: zendesk },
  { brand: "oracle", src: oracle },
]

export const digitalImagesIntegrations = [patagonia, uber, netflix]

export const digitalImagesRestautants = [
  empHandbook,
  employeeHandbook,
  grainRestaurant,
]

export const digitalImagesHealthcare = [
  healthcareEmpHandbook,
  healthcareEmployeeHandbook,
  grainRestaurant,
]

export const digitalImagesAgencies = [
  healthcareEmpHandbook,
  agenciesMarketingEmployeeHandbook,
  agenciesEmployeeHandbook,
]

export const partnersDataRestaurants = [
  { brand: "ricardas", src: ricardas },
  { brand: "gupcakes", src: gupcakes },
  { brand: "barter", src: barter },
  { brand: "food", src: food },
  { brand: "pacific", src: pacific },
  { brand: "coldenrod", src: coldenrod },
  { brand: "relish_works", src: relish_works },
  { brand: "peaks_prints", src: peaks_prints },
  { brand: "petersen", src: petersen },
  { brand: "grain", src: grain },
]

export const partnersDataHealthcare = [
  { brand: "checkerspot", src: checkerspot },
  { brand: "rowden", src: rowden },
  { brand: "coast_to_coast", src: coast_to_coast },
  { brand: "dealnews", src: dealnews },
  { brand: "nom", src: nom },
  { brand: "fusebill", src: fusebill },
  { brand: "vimond", src: vimond },
  { brand: "naritiv", src: naritiv },
  { brand: "finagraph", src: finagraph },
]

export const partnersDataAgencies = [
  { brand: "sevenlayers", src: sevenlayers },
  { brand: "bna", src: bna },
  { brand: "dg", src: dg },
  { brand: "realny", src: realny },
  { brand: "assured", src: assured },
  { brand: "indicate_logo_PR", src: indicate_logo_PR },
  { brand: "hypothesis", src: hypothesis },
  { brand: "pepperland_logo_long", src: pepperland_logo_long },
]
