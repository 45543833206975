/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import { Footer } from "src/sections/landing"

import Plans from "../componentsV2/Plans"
import constant from "src/utils/constant"

const PlansPage = ({ ...props }) => (
  <React.Fragment>
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Pricing & Plans</title>
      <meta
        name="description"
        content={`
          All plans start with a ${constant.trialDays}-day free trial. Need a hand? 
          Give our award-winning support team a shout: +1 646-918-8673, or email us at hello@airmason.com`}
      />
      <script src="https://js.stripe.com/v3/" async={true} />
    </Helmet>
    <Plans {...props} />
    <Footer />
  </React.Fragment>
)

export default PlansPage
