import React, { useState } from "react"
// import styled from "styled-components"

import Partners from "src/componentsV2/sections/Partners"
import Header from "src/componentsV2/sections/Header"
import { cardDatas } from "src/componentsV2/sections/Testimonials/data"

import { WrapperPricing } from "./shared/Layout"
import { ContainerPage } from "../ui/layout"
import PlanPanels from "./PlanPanels"
import Features from "./Features"
import CardDetails from "./CardDetails"
import Testimonials from "../sections/Testimonials"
import ContactSalesModal from "../ContactSalesModal"
import PlansContext from "./context"
import { commonPartnersData } from "../shared/data"

// const HeroContainer = styled(ContainerPage)`
//   height: 42.5rem;
//   margin-bottom: 220px;
//   padding-top: 90px;

//   @media (min-width: 768px) {
//     padding-top: 160px;
//     margin-bottom: 160px;
//   }
// `

const Pricing = ({ transition }: any) => {
  const [isContactSalesOpenModal, setIsContactSalesOpenModal] = useState(false)

  const openContactSalesModal = () => {
    setIsContactSalesOpenModal(true)
  }

  return (
    <PlansContext.Provider value={{ openContactSalesModal }}>
      <Header />
      <WrapperPricing className="lg:px-0 px-15/16">
        <PlanPanels transition={transition} />
      </WrapperPricing>
      <ContainerPage isBackgroundWhite className="pt-0">
        <WrapperPricing className="pt-0">
          <Features transition={transition} />
        </WrapperPricing>
      </ContainerPage>
      <ContainerPage>
        <WrapperPricing>
          <CardDetails />
        </WrapperPricing>
      </ContainerPage>
      <ContainerPage isBackgroundWhite>
        <WrapperPricing>
          <Partners
            partnersData={commonPartnersData}
            title="You’re in good company"
          />
          <Testimonials data={cardDatas} />
        </WrapperPricing>
      </ContainerPage>
      {isContactSalesOpenModal && (
        <ContactSalesModal
          onClose={() => setIsContactSalesOpenModal(false)}
          isOpen={isContactSalesOpenModal}
        />
      )}
    </PlansContext.Provider>
  )
}

export default Pricing
