import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

export const PlanPanelStyled = styled(
  ({ className, children, background }: any) => {
    return (
      <div
        className={className}
        style={{ backgroundImage: "url(" + background + ")" }}
      >
        {children}
      </div>
    )
  }
)`
  text-align: center;
  margin-left: 20px;
  min-width: 160px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 2px 20px 0 rgba(4, 2, 33, 0.13);
  background-size: 95%;
  background-position: bottom right;
`

export const Cell = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-bottom: 1px solid #e2dedc;
  }

  &.plan-panel {
    &:after {
      width: calc(100% + 20px);
      left: -20px;
    }
  }
`

export const FeatureContent = styled.div`
  ${tw`flex mt-10 py-5 feature-container`}
  @media (max-width: 1024px) {
    overflow-x: auto;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #0e1621;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #0e1621;
  }
`
