import React, { useContext } from "react"
import tw from "tailwind.macro"
import styled from "styled-components"
import { css } from "@emotion/core"

import _Form from "src/components/Form"
import Tooltip, {
  TooltipContent,
  TooltipPlacement,
} from "src/componentsV2/ui/Tooltip"

import {
  BillingCycle,
  featuresPlanData,
  FeaturesPlanDataProps,
} from "src/componentsV2/shared/plansData"

import Text from "../../ui/Text"
import { PlanPanelStyled, Cell, FeatureContent } from "./style"
import checkIcon from "../images/icons/check.svg"
import dashIcon from "../images/icons/dash.svg"
import helpIcon from "../images/icons/help.svg"
import { ButtonOutLineBlue, ButtonContact } from "../../ui/Button/styleButton"
import featureStartupIcon from "../images/feature_startup.png"
import smallBizIcon from "../images/small_biz.png"
import enterpriseIcon from "../images/enterprise.png"
import PlansContext from "../context"

const Form = _Form as any

interface BlockProps {
  type: string
  title: string
  background?: any
}

const blocks: Array<BlockProps> = [
  { type: "intro", title: "Features" },
  {
    type: "startUp",
    title: "Startup",
    background: featureStartupIcon,
  },
  {
    type: "smallBiz",
    title: "Small Business",
    background: smallBizIcon,
  },
  {
    type: "enterprise",
    title: "Enterprise",
    background: enterpriseIcon,
  },
]

const BlockTitle = styled.div`
  font-family: Calluna-Regular;
  color: #0f1722;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin-bottom: 1.125rem;
`

const BlockTitleFeatures = styled(BlockTitle)`
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.71px;
  text-transform: uppercase;
  text-align: left;
`

const WrapperFeature = styled.div`
  min-width: 345px;
  ${tw`pt-8 pb-16 flex flex-col`}
`

const Introduces = ({ data }: { data: Array<FeaturesPlanDataProps> }) => {
  return (
    <WrapperFeature>
      <BlockTitleFeatures>Features</BlockTitleFeatures>
      {data.map((item: any, idx: number) => {
        return (
          <Cell key={idx} className="w-full justify-start">
            <Text.note>{item.title}</Text.note>
            {item.desc && (
              <div className="ml-3 h-4">
                <Tooltip
                  placement="top"
                  customContainerClassName={item.customContainerClassName}
                >
                  <TooltipContent>
                    <div className="tooltip-title">{item.title}</div>
                    <div>{item.desc}</div>
                  </TooltipContent>
                  <TooltipPlacement>
                    <img src={helpIcon} />
                  </TooltipPlacement>
                </Tooltip>
              </div>
            )}
          </Cell>
        )
      })}
    </WrapperFeature>
  )
}

const PlanPanel = ({
  data,
  block,
  onChange,
}: {
  data: Array<FeaturesPlanDataProps>
  block: BlockProps
  onChange: any
}) => {
  const billingCycle = BillingCycle.year
  const { openContactSalesModal } = useContext(PlansContext)

  return (
    <PlanPanelStyled
      className={`pt-8 pb-16 bg-no-repeat ${block.type}`}
      background={block.background || ""}
    >
      <BlockTitle>{block.title}</BlockTitle>
      {data.map((item: any, idx: number) => {
        const value = item[billingCycle][block.type]

        if (typeof value === "string") {
          return (
            <Cell className="plan-panel justify-center" key={idx}>
              <Text.subnote
                className={
                  value === "Unlimited" ? "text-orange-1" : "text-black"
                }
              >
                {value}
              </Text.subnote>
            </Cell>
          )
        }

        return (
          <Cell className="plan-panel justify-center" key={idx}>
            {value ? <img src={checkIcon} /> : <img src={dashIcon} />}
          </Cell>
        )
      })}
      <div className="mt-5">
        {block.title === "Enterprise" ? (
          <ButtonContact onClick={() => openContactSalesModal()}>
            Contact Sales
          </ButtonContact>
        ) : (
          <ButtonOutLineBlue
            type="submit"
            onClick={() => onChange && onChange(block.title)}
          >
            Start Trial
          </ButtonOutLineBlue>
        )}
      </div>
    </PlanPanelStyled>
  )
}

const Features = ({ onChange }: any) => {
  return (
    <FeatureContent>
      {blocks.map((block, idx) => {
        if (block.type === "intro") {
          return <Introduces key={idx} data={featuresPlanData} />
        }

        return (
          <PlanPanel
            key={idx}
            data={featuresPlanData}
            block={block}
            onChange={onChange}
          />
        )
      })}
    </FeatureContent>
  )
}

const index = ({ transition }: any) => {
  return (
    <div>
      <Text.title
        css={css`
          ${tw`max-w-92 mx-auto`}
        `}
      >
        Everything you need to get started
      </Text.title>
      <Form
        name="plans"
        initialValues={{}}
        onSubmit={() => {
          transition()
        }}
      >
        <Form.Field name="selectedPlan" component={Features} />
      </Form>
    </div>
  )
}

export default index
