import React from "react"
import tw from "tailwind.macro"
import css from "@emotion/css"

import { SupportListStyled, SupportItemStyled } from "./style"
import Text from "src/componentsV2/ui/Text"

const SupportItem = (props: any) => {
  const { data, isRestaurantPage } = props

  const getMaxHeight = () => {
    const partners = {
      coldenrod: "135px",
      petersen: "60px",
      bna: "122px",
      realny: "26px",
      dg: "76px",
      checkerspot: "inherit",
      coast_to_coast: "inherit",
      sevenlayers: "inherit",
      box: "30px",
    } as any
    const brand = data.brand

    if (isRestaurantPage) {
      return ["coldenrod", "petersen"].includes(brand)
        ? partners[brand]
        : "73px"
    }
    return partners[brand] ?? "50px"
  }

  return (
    <SupportItemStyled isRestaurantPage>
      <img
        css={css`
          max-height: ${getMaxHeight()};
          max-width: ${isRestaurantPage ? "171px" : "150px"};
        `}
        className="w-auto h-auto"
        src={data.src}
        alt={data.brand}
      />
    </SupportItemStyled>
  )
}

interface PartnersListProps {
  partnersData: any[]
  title: string
  isRestaurantPage?: any
}

const PartnersList: React.FC<PartnersListProps> = (props: any) => {
  const { partnersData, title } = props
  return (
    <React.Fragment>
      <Text.title
        css={css`
          ${tw`pb-14 max-w-108 mx-auto`}
        `}
      >
        {title}
      </Text.title>
      <SupportListStyled>
        {partnersData.map((item: any) => (
          <SupportItem key={item.brand} data={item} {...props} />
        ))}
      </SupportListStyled>
    </React.Fragment>
  )
}

export default PartnersList
